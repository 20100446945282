@import '~@paprika/tokens/lib/tokens.scss';

$award--size: 125px;

.page-title {
  font-weight: 900;
  font-size: 46px;
  color: #3f3d3c;
  padding-bottom: 15px;
  border-bottom: 1px solid #d7d7d7;
}

.main-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 0;
  font-family: $font-family--default;
  padding: 2.5vh 0;
}

.main-container__content {
  border-radius: $border--radius;
  max-width: 1280px;
  width: 60vw;
}

@media only screen and (max-width: 60em) {
  .main-container__content {
    width: 75vw;
  }
}

.people-list-container {
  font-size: 20px;
}

.people-list {
  padding: 0 0px 10px 0;
}

.people-list__link {
  padding: 10px;
  text-decoration: none;
  font-weight: bold;
  color: $color--purple;
}

.badge {
  display: inline-block;
  margin: 10px 0;
}
.badge__image {
  width: $award--size;
  height: $award--size;

  // Style alternative text if badge is missing
  font-size: 22px;
  text-align: center;
}
.badge__issue-date {
  padding: 2px 0;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.dot-award {
  color: #daa520;
}
.dot-badge {
  color: $color--purple-darken-20;
}
